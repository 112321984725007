<template>
  <div class="edp-backend">
    <edp-backend-user />

    <section class="edp-backend-middle">
      <edp-backend-menu />

      <!-- content -->
      <section class="edp-backend-content">
        <div class="edp-backend-content-out">
          <mainTitle> </mainTitle>

          <section class="edp-backend-common-table">
            <div class="table-headControlLine">
              <div>
                <el-button type="primary" size="small" @click="createRow">
                  {{ $t("edpBackendCommon.add") }}</el-button
                >
              </div>
            </div>

            <el-table
              stripe
              row-key="id"
              :data="pageList"
              style="width: 100%"
              v-loading="loading"
            >
              <el-table-column
                fixed
                prop="catCnName"
                :label="$t('edpBackendEntry.ctableTitle1')"
                width="230"
              >
              </el-table-column>

              <el-table-column
                prop="catEnName"
                :label="$t('edpBackendEntry.ctableTitle2')"
              >
              </el-table-column>

              <el-table-column
                prop="catSorted"
                :label="$t('edpBackendMenu.sort')"
              >
              </el-table-column>
              <el-table-column
                prop="createTime"
                :label="$t('edpBackendEntry.tableTite6')"
              >
              </el-table-column>
              <el-table-column
                :label="$t('edpBackendMenu.control')"
                fixed="right"
                width="150"
              >
                <template slot-scope="scope">
                  <el-button type="text" @click="setRow(scope.row)">
                    {{ $t("edpBackendCommon.edit") }}</el-button
                  >
                  <!-- <el-button type="text" @click="deleteRow(scope.row)">
                    {{ $t("edpBackendCommon.delete") }}</el-button
                  > -->
                </template>
              </el-table-column>
            </el-table>
          </section>
        </div>
      </section>
      <!-- content end -->
    </section>

    <el-dialog
      class="edp-backend-dialogEl"
      v-if="dialogVisible"
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="540px"
    >
      <el-form ref="form" :rules="rules" :model="form" label-width="160px">
        <el-form-item
          :label="$t('edpBackendEntry.ctableTitle1')"
          prop="catCnName"
        >
          <el-input v-model="form.catCnName" clearable></el-input>
        </el-form-item>

        <el-form-item
          :label="$t('edpBackendEntry.ctableTitle2')"
          prop="catEnName"
        >
          <el-input v-model="form.catEnName" clearable></el-input>
        </el-form-item>

        <el-form-item :label="$t('edpBackendMenu.selectParent')">
          <el-cascader
            v-model="form.catParentId"
            :options="menuOptions"
            :props="{ checkStrictly: true }"
            clearable
          ></el-cascader>
        </el-form-item>

        <el-form-item :label="$t('edpBackendMenu.sort')">
          <el-input v-model="form.catSorted" clearable></el-input>
        </el-form-item>
        <el-form-item :label="$t('edpBackendEntry.dtableTitle8')">
          <el-switch
            v-model="form.isEnabled"
            active-color="rgba(97, 191, 180, 1)"
            inactive-color="rgba(0, 0, 0, 0.24705882352941178)"
          >
          </el-switch>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{
          $t("edpBackendCommon.canale")
        }}</el-button>
        <el-button type="primary" @click="dialogVisible_submit">{{
          $t("edpBackendCommon.define")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  name: "backendCategory",
  data() {
    return {
      pageList: [],
      currentPage: 1,
      pageSize: 1000,
      loading: true,

      dialogTitle: "",
      dialogVisible: false,
      dialogType: "",

      form: {
        id: 0,
        catCnName: "",
        catEnName: "",
        catParentId: "",
        catSorted: 0,
        isEnabled: false,
      },
      menuOptions: [],

      rules: {
        catCnName: [
          {
            required: true,
            message:
              this.$t("edpBackendCommon.placeholder") +
              "" +
              this.$t("edpBackendEntry.ctableTitle1"),
            trigger: "blur",
          },
        ],
        catEnName: [
          {
            required: true,
            message:
              this.$t("edpBackendCommon.placeholder") +
              "" +
              this.$t("edpBackendEntry.ctableTitle2"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    ...mapActions({
      categoryList: "user/categoryList",
      addCategory: "user/addCategory",
      editCategory: "user/editCategory",
      menuDelete: "backendMenu/menuDelete",
    }),

    async pageInit(num) {
      let _this = this;
      this.loading = true;

      if (num) {
        this.currentPage = num;
      }

      let params = {
        current: this.currentPage,
        size: this.pageSize,
      };

      let res = await this.categoryList(params);

      this.pageList = res.data;

      this.menuOptions = JSON.parse(JSON.stringify(this.pageList));

      function menuListInit(data) {
        let pageLs = data;
        pageLs.forEach((val, e) => {
          _this.$set(val, "value", val.id);
          _this.$i18n.locale === "cn"
            ? _this.$set(val, "label", val.catCnName)
            : _this.$set(val, "label", val.catEnName);

          if (val.children && val.children.length > 0) {
            menuListInit(val.children);
          }
        });
      }

      menuListInit(this.menuOptions);

      this.loading = false;
    },
    createRow() {
      this.dialogType = "";
      this.dialogTitle = this.$t("edpBackendCommon.add");

      for (var key in this.form) {
        this.form[key] = key === "catSorted" ? 0 : "";
      }

      this.dialogVisible = true;
    },
    setRow(data) {
      this.dialogType = "update";
      this.dialogTitle = this.$t("edpBackendCommon.edit");

      for (var key in this.form) {
        this.form[key] = data[key];
      }

      this.dialogVisible = true;
    },
    deleteRow(data) {
      this.$confirm(
        this.$t("edpBackendCommon.actionConfirm"),
        this.$t("edpBackendCommon.noticeTitle"),
        {
          confirmButtonText: this.$t("edpBackendCommon.define"),
          cancelButtonText: this.$t("edpBackendCommon.canale"),
        }
      ).then(async () => {
        let res = await this.menuDelete(data.id);

        if (res.data.success) {
          this.$message({
            type: "success",
            message: this.$t("edpBackendCommon.actionSuccess"),
          });

          this.pageInit(1);
        }
      });
    },
    dialogVisible_submit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          let res = {};

          let formParams = JSON.parse(JSON.stringify(this.form));

          if (!formParams.catSorted) {
            formParams.catSorted = 0;
          }
          console.log(this.form.catParentId.length);
          if (this.form.catParentId && this.form.catParentId.length > 0) {
            formParams.catParentId =
              this.form.catParentId[this.form.catParentId.length - 1];
          } else if (
            this.form.catParentId &&
            this.form.catParentId.length === undefined
          ) {
            formParams.catParentId = this.form.catParentId;
          } else {
            formParams.catParentId = "";
          }

          if (this.dialogType === "update") {
            res = await this.editCategory(formParams);
          } else {
            res = await this.addCategory(formParams);
          }

          if (res.success) {
            this.$message({
              type: "success",
              message: this.$t("edpBackendCommon.actionSuccess"),
            });

            this.dialogVisible = false;

            this.pageInit(1);
          }
        }
      });
    },
  },
  async mounted() {
    this.pageInit();
  },
};
</script>
